import React from 'react'
import * as styles from './Privacy.module.scss';

const Privacy = ({ data }) => <main className={`${styles.container}`}>
  {data.map(article =>
    <section key={article.header}>
      <h1 className={`${styles.header}`}>{article.header}</h1>
      {article.data.map(text =>
        <p dangerouslySetInnerHTML={{__html: text}} key={text} className={`${styles.text}`}></p>
      )}
    </section>
  )}
</main>

export default Privacy