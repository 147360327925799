const data = [
  {
    header: `"PRIVACY POLICY"`,
    data: [
      "Last updated May 01, 2023", 
      `This privacy notice for ash.fans ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:`,
      `Visit our website at <a href="https://ash.fans" style="display: inline-block">https://ash.fans</a>, or any website of ours that links to this privacy notice`,
      "Engage with us in other related ways, including any sales, marketing, or events",
      "Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at hello@ash.fans.com."
    ]
  },
  {
    header: "SUMMARY OF KEY POINTS",
    data: [
      "This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.",
      "What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with ash.fans and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.",
      "Do we process any sensitive personal information? We do not process sensitive personal information.",
      "Do we receive any information from third parties? We do not receive any information from third parties.",
      "How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.",
      "In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.",
      "How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.",
      "What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.",
      "How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.",
      "Want to learn more about what ash.fans does with any information we collect? Review the privacy notice in full.",
      "Personal information you disclose to us",
      "In Short: We collect personal information that you provide to us.",
      "We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.",
      "Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: email addresses, names, usernames, passwords, contact preferences.",
      "Sensitive Information. We do not process sensitive information.",
      `Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.`,
      "All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.",
      "Information automatically collected",
      "In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.",
      "We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.",
      "The information we collect includes:",
      "Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).",
      "Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.",
      "Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note, however, that opting out of our collection of location information will cause location-based features to be disabled."
    ]
  },
  {
    header: "HOW DO WE PROCESS YOUR INFORMATION?",
    data: [
      "In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.",
      "We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.",
      "We use the information we collect or receive:",
      `To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" for further information.`,
      "To post testimonials. We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update or delete your testimonial, please contact us at hello@ash.fans.com and be sure to include your name, testimonial location, and contact information.",
      "Request feedback. We may use your information to request feedback and to contact you about your use of our Services.",
      "To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.",
      "To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.",
      "To send administrative information to you. We may use your personal information to send you product, service, and new feature information and/or information about changes to our terms, conditions, and policies.",
      "To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).",
      "To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.",
      "To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.",
      "To manage user accounts. We use your information for the purposes of managing our account and keeping it in working order.",
      "For other business purposes. We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use identifiable personal information without your consent."
    ]
  },
  {
    header: "WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?",
    data: [
      "In Short: We only process your personal information when we believe it is necessary and we have a valid legal basis to do so under applicable law, such as with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill our contractual obligations to you.",
      "We only process your personal information when we believe it is necessary and we have a legal basis for doing so under applicable law, such as:",
      "With your consent. We may process your personal information if you have given us specific consent to use your personal information for a specific purpose.",
      "To comply with laws. We may process your personal information to comply with applicable laws and regulations, court orders, or government and law enforcement requests.",
      "To provide services to you. We may process your personal information when it is necessary to enter into a contract with you or to perform a contract that you have with us.",
      "To protect your vital interests or those of another person. We may process your personal information when it is necessary to protect your vital interests or those of another person.",
      "For our legitimate interests. We may process your personal information when it is necessary for our legitimate interests, such as to improve our services, marketing efforts, customer service, and our Services' security."
    ]
  },
  {
    header: "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?",
    data: [
      "In Short: We may share information in specific situations described in this section and/or with the following third parties.",
      "We may need to share your personal information in the following situations:",
      "Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.",
      "Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service, and marketing efforts. We may allow selected third parties to use tracking technology on the Services, which will enable them to collect data on our behalf about how you interact with our Services over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages, or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent, or trade any of your information with third parties for their promotional purposes.",
      "Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.",
      "With your Consent. We may disclose your personal information for any other purpose with your consent.",
      "Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile."
    ]
  },
  {
    header: "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",
    data: [
      "In Short: We may use cookies and other tracking technologies to collect and store your information.",
      "We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice."
    ]
  },
  {
    header: "HOW DO WE HANDLE YOUR SOCIAL LOGINS?",
    data: [
      "In Short: If you choose to register or log in to our services using a social media account, we may have access to certain information about you.",
      "Our Services offer you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture, as well as other information you choose to make public on such social media platform.",
      "We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps."
    ]
  },
  {
    header: "HOW LONG DO WE KEEP YOUR INFORMATION?",
    data: [
      "In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.",
      "We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.",
      "When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible."
    ]
  },
  {
    header: "HOW DO WE KEEP YOUR INFORMATION SAFE?",
    data: [
      "In Short: We aim to protect your personal information through a system of organizational and technical security measures.",
      "We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment."
    ]
  },
  {
    header: "DO WE COLLECT INFORMATION FROM MINORS?",
    data: [
      "In Short: We do not knowingly collect data from or market to children under 18 years of age.",
      "We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at hello@ash.fans.com."
    ]
  },
  {
    header: "WHAT ARE YOUR PRIVACY RIGHTS?",
    data: [
      "In Short: You may review, change, or terminate your account at any time.",
      `If you are a resident in the European Economic Area (EEA) or United Kingdom (UK) and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm." style="display:inline-block">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a>`,
      `If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html." style="display: inline-block">https://www.edoeb.admin.ch/edoeb/en/home.html.</a>`,
      "If you have questions or comments about your privacy rights, you may email us at hello@ash.fans.com.",
      "Account Information",
      "If you would at any time like to review or change the information in your account or terminate your account, you can:",
      "Log in to your account settings and update your user account.",
      "Contact us using the contact information provided.",
      "Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and/or comply with applicable legal requirements.",
      `Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services, visit <a href="http://www.aboutads.info/choices/." style="display: inline-block">http://www.aboutads.info/choices/.</a>`,
      "Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list — however, we may still communicate with you, for example, to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:",
      "Access your account settings and update your preferences.",
      "Contact us using the provided information."
    ]
  },
  {
    header: "CONTROLS FOR DO-NOT-TRACK FEATURES",
    data: [
      'Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.'
    ]
  },
  {
    header: "DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",
    data: [
      "In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.",
      "California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.",
      "If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).",
      "CCPA Privacy Notice",
      "The California Code of Regulations defines a “resident” as:",
      "(1) every individual who is in the State of California for other than a temporary or transitory purpose and",
      "(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose",
      "All other individuals are defined as “non-residents.”",
      "If this definition of “resident” applies to you, we must adhere to certain rights and obligations regarding your personal information.",
      "What categories of personal information do we collect?",
      "We have collected the following categories of personal information in the past twelve (12) months:",
      "Category Examples Collected",
      "A. Identifiers Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name",
      "YES",
      "B. Personal information categories listed in the California Customer Records statute Name, contact information, education, employment, employment history, and financial information",
      "YES",
      "C. Protected classification characteristics under California or federal law Gender and date of birth",
      "NO",
      "D. Commercial information Transaction information, purchase history, financial details, and payment information",
      "YES",
      "E. Biometric information Fingerprints and voiceprints",
      "NO",
      "F. Internet or other similar network activity Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements",
      "YES",
      "G. Geolocation data Device location",
      "YES",
      "H. Audio, electronic, visual, thermal, olfactory, or similar information Images and audio, video or call recordings created in connection with our business activities",
      "NO",
      "I. Professional or employment-related information Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us",
      "YES",
      "J. Education Information Student records and directory information",
      "NO",
      "K. Inferences drawn from other personal information Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics",
      "YES",
      "We may also collect other personal information outside of these categories instances where you interact with us in person, online, or by phone or mail in the context of:",
      "Receiving help through our customer support channels;",
      "Participation in customer surveys or contests; and",
      "Facilitation in the delivery of our Services and to respond to your inquiries.",
      "How do we use and share your personal information?",
      "More information about our data collection and sharing practices can be found in this privacy notice.",
      "You may contact us by email at hello@ash.fans.com, or by referring to the contact details at the bottom of this document.",
      "If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.",
      "Will your information be shared with anyone else?",
      "We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.",
      `We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.`,
      "ash.fans has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 months. ash.fans will not sell personal information in the future belonging to website visitors, users, and other consumers.",
      "Your rights with respect to your personal data",
      "Right to request deletion of the data - Request to delete",
      "You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.",
      "Right to be informed - Request to know",
      "Depending on the circumstances, you have a right to know:",
      "whether we collect and use your personal information;",
      "the categories of personal information that we collect;",
      "the purposes for which the collected personal information is used;",
      "whether we sell your personal information to third parties;",
      "the categories of personal information that we sold or disclosed for a business purpose;",
      "the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and",
      "the business or commercial purpose for collecting or selling personal information.",
      "In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.",
      "Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights",
      "We will not discriminate against you if you exercise your privacy rights.",
      "Verification process",
      "Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.",
      "We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.",
      "Other privacy rights",
      "you may object to the processing of your personal data",
      "you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data",
      "you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.",
      "you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.",
      "To exercise these rights, you can contact us by email at hello@ash.fans.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you."
    ]
  },
  {
    header: "DO WE MAKE UPDATES TO THIS NOTICE?",
    data: [
      "In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.",
      "We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information."
    ]
  }
]

export default data